import React, { useEffect, useState } from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";

// Import Custom Component
import ALink from "../ALink";

import { useRoutes } from "react-router";
import axios from "axios";

function SearchForm() {
  const API_URL = process.env.React_APP_API_ENDPOINT;
  const router = ""; //useRoutes();
  const [cat, setCat] = useState("");
  const [search, setSearch] = useState("");
  const [searchProducts, setSearchProducts] = useState("");
  const [searchResult, setSearchResults] = useState([]);
  // const [searchProducts, { data }] = useLazyQuery(GET_PRODUCTS);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    document.querySelector("body").addEventListener("click", onBodyClick);

    return () => {
      document.querySelector("body").removeEventListener("click", onBodyClick);
    };
  }, []);

  useEffect(() => {
    if (typeof search.length !== "undefined" && search.length > 2) {
      if (timer) clearTimeout(timer);
      axios
        .get(API_URL + "search_products/" + search)
        .then((response) => {
          let results = response.data;
          if (results.status === 200) {
            console.log(results.data);

            let timerId = setTimeout(() => {
              //searchProducts({ variables: { search: search, category: cat } });
              setSearchResults(results.data);
              setTimer(null);
            }, 500);
            setTimer(timerId);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [search, cat]);

  useEffect(() => {
    document.querySelector(".header-search.show-results") &&
      document
        .querySelector(".header-search.show-results")
        .classList.remove("show-results");
  }, [router.pathname]);

  useEffect(() => {
    setSearch("");
    setCat("");
  }, [router.query]);

  function removeXSSAttacks(html) {
    const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;

    // Removing the <script> tags
    while (SCRIPT_REGEX.test(html)) {
      html = html.replace(SCRIPT_REGEX, "");
    }

    // Removing all events from tags...
    html = html.replace(/ on\w+="[^"]*"/g, "");

    return {
      __html: html,
    };
  }

  function matchEmphasize(name) {
    let regExp = new RegExp(search, "i");
    return name.replace(regExp, (match) => "<strong>" + match + "</strong>");
  }

  function onSearchClick(e) {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.parentNode.classList.toggle("show");
  }

  function onBodyClick(e) {
    if (e.target.closest(".header-search"))
      return (
        e.target.closest(".header-search").classList.contains("show-results") ||
        e.target.closest(".header-search").classList.add("show-results")
      );

    document.querySelector(".header-search.show") &&
      document.querySelector(".header-search.show").classList.remove("show");
    document.querySelector(".header-search.show-results") &&
      document
        .querySelector(".header-search.show-results")
        .classList.remove("show-results");
  }

  function onCatSelect(e) {
    setCat(e.target.value);
  }

  function onSearchChange(e) {
    setSearch(e.target.value);
  }

  function onSubmitSearchForm(e) {
    e.preventDefault();
    return false;
    router.push({
      pathname: "/shop",
      query: {
        search: search,
        category: cat,
      },
    });
  }

  return (
    <div className="header-icon header-search header-search-inline header-search-category d-none d-sm-block border-left-0 mb-0">
      <a
        href="#"
        className="search-toggle"
        role="button"
        onClick={onSearchClick}
      >
        <i className="icon-magnifier"></i>
      </a>
      <form action="#" method="get" onSubmit={(e) => onSubmitSearchForm(e)}>
        <div className="header-search-wrapper">
          <input
            type="search"
            className="form-control bg-transparent"
            placeholder="Search Products..."
            value={search}
            required
            onFocus={(e) => {
              setSearchResults([]);
            }}
            onChange={(e) => onSearchChange(e)}
          />

          <button
            className="btn icon-magnifier bg-transparent border-left-0"
            title="search"
            type="submit"
          ></button>
        </div>
      </form>
      <div className="live-search-list">
        {typeof searchResult !== "undefined" &&
          searchResult &&
          searchResult.map((product, index) => (
            <ALink
              href={`/product/${product.slug}`}
              className="autocomplete-suggestion"
              key={`search-result-${index}`}
            >
              <LazyLoadImage
                src={product.images[0]["media"].url}
                width={40}
                height={40}
                alt="product"
              />
              <div
                className="search-name"
                dangerouslySetInnerHTML={removeXSSAttacks(
                  matchEmphasize(product.name)
                )}
              ></div>
              <span className="search-price">
                {product.price === product.sale_price ? (
                  <span className="product-price">
                    {process.env.React_APP_CURRENCY_SYMBOLS + product.price}
                  </span>
                ) : (
                  <>
                    <span className="old-price">
                      {process.env.React_APP_CURRENCY_SYMBOLS + product.price}
                    </span>
                    <span className="product-price">
                      {process.env.React_APP_CURRENCY_SYMBOLS +
                        product.sale_price}
                    </span>
                  </>
                )}
              </span>
            </ALink>
          ))}
      </div>
    </div>
  );
}

export default SearchForm;
