import axios from "axios";
import { useContext, useReducer } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import reducer from "../reducer/reducerProduct";

const AppContext = createContext();

const initialState = {
  isLoading: false,
  isError: false,
  products: [],
  featureProduct: [],
  newProduct:[],
  hotProduct: [],
  topRated:[],
  isSingleLoading: false,
  singleProduct: {},
  searchFilter: {},
};
const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getProducts = async () => {
    dispatch({ type: "SET_LOADING" });
    try {
      const result = await axios.get(
        process.env.React_APP_API_ENDPOINT + "get_products"
      );
      const data = await result.data.data;
      dispatch({ type: "SET_ALL_PRODUCTS", payload: data });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  const getAllProducts = async (parameters = null) => {
    const param = parameters["variables"];
    
    dispatch({ type: "SET_LOADING" });
    dispatch({ type: "SET_SEARCH_FILTERS", payload: param});
    try {
      const result = await axios.get(
        process.env.React_APP_API_ENDPOINT + "get_all_products",
        {
          params: {
            param
          },
        }
      );
      const data = await result.data.data;
      dispatch({ type: "SET_ALL_PRODUCTS", payload: data });
      
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  const getSingleProduct = async (product_slug) => {
    dispatch({ type: "SET_SINGLE_LOADING" });
    try {
      const result = await axios.get(
        process.env.React_APP_API_ENDPOINT + "get_product/" + product_slug
      );
      const singleProduct = await result.data.data;
      dispatch({ type: "SET_SINGLE_PRODUCTS", payload: singleProduct });
    } catch (error) {
      dispatch({ type: "SINGLE_API_ERROR" });
    }
  };

  useEffect(() => {
    getProducts();
  }, []);
  return (
    <AppContext.Provider value={{ ...state, getAllProducts, getSingleProduct }}>
      {children}
    </AppContext.Provider>
  );
};

const useProductContext = () => {
  return useContext(AppContext);
};

export { AppProvider, AppContext, useProductContext };
