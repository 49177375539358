import React, { useEffect } from "react";

import { connect } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";

// Import Actions
import { actions as WishlistAction } from "../../../store/wishlist";
import { actions as CartAction } from "../../../store/cart";
import { actions as ModalAction } from "../../../store/modal";

// Import Custom Component
import ALink from "../../../components/common/ALink";
import { useRoutes } from "react-router";
import CountDown from "../countdown";
import { useCartContext } from "../../../context/cartContext";
import { useWishListContext } from "../../../context/wishlistContext";

function ProductOne(props) {
  const { addToCart } = useCartContext();
  const { addToWishList} = useWishListContext();
  
  const router = ""; //useRoutes();
  const { adClass = "", link = "default", product } = props;

  function isSale() {
    return product.price !== null &&
      product.price > 0 &&
      product.variants.length === 0
      ? "-" +
          (
            (100 * (product.sale_price - product.price)) /
            product.sale_price
          ).toFixed(0) +
          "%"
      : product.variants.find((variant) => variant.sale_price)
      ? "Sale"
      : false;
  }

  function isInWishlist() {
    return (
      product &&
      props.wishlist?.findIndex((item) => item.slug === product.slug) > -1
    );
  }

  function onWishlistClick(e) {
    e.preventDefault();
    if (!isInWishlist()) {
      let target = e.currentTarget;
      target.classList.add("load-more-overlay");
      target.classList.add("loading");

      setTimeout(() => {
        target.classList.remove("load-more-overlay");
        target.classList.remove("loading");
        addToWishList(product);
      }, 1000);
    } else {
      router.push("/pages/wishlist");
    }
  }

  function onAddCartClick(e,product) {
    e.preventDefault();
    addToCart(product ,1,1);
  }

  function onQuickViewClick(e,product) {
    e.preventDefault();
    props.showQuickView(product.slug);
  }

  return (
    <div
      className={`product-default1 inner-quickview inner-icon media-with-lazy ${adClass}`}
    >
      <figure>
        <ALink href={`/product/${product.slug}`}>
          <div className="lazy-overlay"></div>
          <LazyLoadImage
            alt="product"
            src={product.images[0]["media"].url}
            threshold={500}
            effect="black and white"
            width="100%"
            height="auto"
          />
          {product.images.length >= 2 ? (
            <LazyLoadImage
              alt="product"
              src={product.images[1]["media"].url}
              threshold={500}
              effect="black and white"
              wrapperClassName="product-image-hover"
            />
          ) : (
            ""
          )}
        </ALink>

        <div className="label-group">
          {product.is_hot ? (
            <div className="product-label label-hot">HOT</div>
          ) : (
            ""
          )}

          {isSale() ? (
            <div className="product-label label-sale">{isSale()}</div>
          ) : (
            ""
          )}
        </div>

        <div className="btn-icon-group">
          {product.variants.length > 0 ? (
            <ALink
              href={`/product/${product.slug}`}
              className="btn-icon btn-add-cart"
            >
              <i className="fa fa-arrow-right"></i>
            </ALink>
          ) : (
            <a
              href="#"
              className="btn-icon btn-add-cart product-type-simple"
              title="Add To Cart"
              onClick={(e) => onAddCartClick(e,product)}
            >
              <i className="icon-shopping-cart"></i>
            </a>
          )}
        </div>

        {product.until && product.until !== null && (
          <CountDown product={product} />
        )}
      </figure>

      <div className="product-details">
        <div className="category-wrap">
          <div className="category-list">
            {product.categories
              ? product.categories.map((item, index) => (
                  <React.Fragment key={item.slug + "-" + index}>
                    <ALink
                      href={{
                        pathname: "/shop",
                        query: { category: item.slug },
                      }}
                    >
                      {item.name}
                    </ALink>
                    {index < product.categories.length - 1 ? ", " : ""}
                  </React.Fragment>
                ))
              : ""}
          </div>

          <a
            href="#"
            className={`btn-icon-wish ${
              isInWishlist() ? "added-wishlist" : ""
            }`}
            onClick={onWishlistClick}
            title={`${
              isInWishlist() === true ? "Go to Wishlist" : "Add to Wishlist"
            }`}
          >
            <i className="icon-heart"></i>
          </a>
        </div>

        <h3 className="product-title">
          <ALink href={`/product/${product.slug}`}>{product.name}</ALink>
        </h3>

        <div className="ratings-container">
          <div className="product-ratings">
            <span
              className="ratings"
              style={{ width: 20 * product.ratings + "%" }}
            ></span>
            <span className="tooltiptext tooltip-top">
              {product.ratings?.toFixed(2)}
            </span>
          </div>
        </div>

        <div className="price-box">
          {product["price"] !== null &&
          product["price"].length > 0 &&
          product["price"] !== 0 ? (
            <span className="product-price">
              {process.env.React_APP_CURRENCY_SYMBOLS +
                product.price?.toFixed(2)}
            </span>
          ) : product.variants.length > 0 ? (
            product.variants.map((item, index) => (
              <span className="product-price" key={index}>
                {process.env.React_APP_CURRENCY_SYMBOLS + item.price}
                {/* {"$" + product.price?.toFixed(2)} */}
              </span>
            ))
          ) : (
            <>
              <span className="old-price">
                {process.env.React_APP_CURRENCY_SYMBOLS +
                  product.price.toFixed(2)}
              </span>
              <span className="product-price">
                {process.env.React_APP_CURRENCY_SYMBOLS +
                  product.sale_price.toFixed(2)}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    wishlist: state.wishlist.list ? state.wishlist.list : [],
  };
};

//export default connect(mapStateToProps)(ProductOne);
export default ProductOne;
