import axios from "axios";
import { useContext, useReducer } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import reducer from "../reducer/reducerCategory";

const CategoriesContext = createContext();

const initialState = {
  isLoading: false,
  isError: false,
  categories: [],
};
const CategoriesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getAllCategories = async () => {
    dispatch({ type: "SET_LOADING" });
    try {
      const result = await axios.get(
        process.env.React_APP_API_ENDPOINT + "get_categories"
      );
      const data = await result.data.data;
      dispatch({ type: "SET_ALL_CATEGORIES", payload: data });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);
  return (
    <CategoriesContext.Provider value={{ ...state }}>
      {children}
    </CategoriesContext.Provider>
  );
};

const useCategoriesContext = () => {
  return useContext(CategoriesContext);
};

export { CategoriesProvider, CategoriesContext, useCategoriesContext };
