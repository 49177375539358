import React from "react";
import { useState, useEffect } from "react";
// import { useRouter } from 'next/router';

// Import Custom Component
import ALink from "../../src/components/common/ALink";
import ProductMediaOne from "../../src/components/partials/product/media/product-media-one";
import ProductDetailOne from "../../src/components/partials/product/details/product-detail-one";
import ProductWidgetContainer from "../../src/components/partials/product/widgets/product-widget-container";
import RelatedProducts from "../../src/components/partials/product/widgets/related-products";
import SingleTabFive from "../../src/components/partials/product/tabs/single-tab-five";
import { useParams } from "react-router-dom";
import { GetProductDetail } from "../query";
import { useProductContext } from "../context/productContext";

const ProductDetailScreen = () => {
  const { isSingleLoading, getSingleProduct, singleProduct } =
    useProductContext();

  useEffect(() => {
    let offset =
      document.querySelector(".main").getBoundingClientRect().top +
      window.pageYOffset -
      58;
    window.scrollTo({ top: offset, behavior: "smooth" });
  }, []);

  const { slug } = useParams();

  const [related, setRelated] = useState([]);
  const [error, setError] = useState([]);

  // if ( !useRouter().query.slug ) return (
  //     <div className="loading-overlay">
  //         <div className="bounce-loader">
  //             <div className="bounce1"></div>
  //             <div className="bounce2"></div>
  //             <div className="bounce3"></div>
  //         </div>
  //     </div>
  // );
  useEffect(() => {
    getSingleProduct(slug);
  }, []);

  if (error && error.length > 0) {
    return <div>{error.message}</div>;
  }

  return (
    <main className="main">
      <div className="container">
        <nav aria-label="breadcrumb" className="breadcrumb-nav mb-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <ALink href="/">home</ALink>
            </li>
            <li className="breadcrumb-item">
              <ALink href="/products">Products</ALink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {singleProduct && singleProduct.name}
            </li>
          </ol>
        </nav>
      </div>

      <div
        className={`container skeleton-body skel-shop-products ${
          isSingleLoading ? "" : "loaded"
        }`}
      >
        <div className={`product-single-container product-single-default`}>
          <div className="row">
            <ProductMediaOne product={singleProduct} />
            <ProductDetailOne product={singleProduct} isNav={false} />
          </div>
        </div>

        <SingleTabFive product={singleProduct} isCustomTab={true} />
        <RelatedProducts products={related} loading={isSingleLoading} />

        <hr className="mt-0 mb-5" />
      </div>
      <ProductWidgetContainer />
    </main>
  );
};

export default ProductDetailScreen;
