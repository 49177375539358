import { connect } from "react-redux";

import SlideToggle from "react-slide-toggle";
import ALink from "../../src/components/common/ALink";
import { getCartTotal } from "../../src/utils";
import { useCartContext } from "../context/cartContext";
import { useEffect, useState } from "react";
import { useUserContext } from "../context/UserContext";
import { useNavigate } from "react-router";

const ThankForOrder = () => {
  const navigate = useNavigate();

  const { cart, removeCartItems, placedOrder, placedOrderStatus } =
    useCartContext();
  const { isLogin, userProfile } = useUserContext();

  const [firstName, setFirstName] = useState();
  const [cartDetail, setCartDetail] = useState([]);
  const [lastName, setLastName] = useState();
  const [country, setCountry] = useState();
  const [city, setCity] = useState();
  const [streetAddress, setStreetAddress] = useState();
  const [zipCode, setZipCode] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [notes, setNotes] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(1);

  useEffect(() => {
    if (isLogin === false) {
      navigate("/login");
    }
  }, [isLogin]);

  useEffect(() => {
    setCartDetail(cart);

    setTimeout(() => {
      removeCartItems();
    }, 5000);
  }, []);

  useEffect(() => {
    if (isLogin === true) {
      if (typeof userProfile != "undefined" && userProfile !== null) {
        setFirstName(userProfile.name);
        setLastName(userProfile.last_name);
        setCity(userProfile.city);
        setEmail(userProfile.email);
        setPhone(userProfile.phone);
        setZipCode(userProfile.postal_code);
        setStreetAddress(userProfile.shipping_address);
        setCountry(userProfile.country_id);
      }
    }
  }, [userProfile]);

  return (
    <main className="main main-test">
      <div className="container checkout-container">
        <ul className="checkout-progress-bar d-flex justify-content-center flex-wrap">
          <li className="disabled">
            <ALink href="/cart">Shopping Cart </ALink>
          </li>
          <li className="disabled">
            <ALink href="#">Checkout</ALink>
          </li>
          <li className="active">
            <ALink href="/thank-you-for-order">Order Complete</ALink>
          </li>
        </ul>
        {cartDetail.length === 0 ? (
          <div className="cart-empty-page text-center">
            <p className="noproduct-msg mb-2">your cart is empty.</p>
            <i className="icon-bag-2"></i>
            <p>No products added to the cart</p>
            <ALink
              href="/products"
              className="btn btn-dark btn-add-cart product-type-simple btn-shop font1"
            >
              return to shop
            </ALink>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-lg-7">
                <div className="thankyou-div text-center">
                  <i
                    className="fa fa-check-circle order-success-icon"
                    style={{ fontSize: "54px", color: "green" }}
                  ></i>
                  <h4 className="thankyou-title">
                    Thank you for your Purchase
                  </h4>
                  <p>
                    <span>Your Order No. : </span>
                    <br /> A copy of your receipt has been sent to
                  </p>
                </div>
                <h4 className="order-subheading text-left">Delivery Details</h4>

                <hr />
                <div className="delivery-detail">
                  <div className="row">
                    <div className="col-lg-6">
                      <table className="table ">
                        <tr>
                          <th>Name</th>
                          <td>{firstName}</td>
                        </tr>
                        <tr>
                          <th> Phone No</th>
                          <td>{phone}</td>
                        </tr>
                      </table>
                    </div>
                    <div className="col-lg-6">
                      <label className="form-head-label mb-3">
                        Delivery Address
                      </label>
                      <p>{streetAddress}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-5">
                <div className="order-summary">
                  <h3>YOUR ORDER</h3>

                  <table className="table table-mini-cart">
                    <thead>
                      <tr>
                        <th colSpan="2">Product</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartDetail.map((item, index) => (
                        <tr key={"checks" + index}>
                          <td className="product-col">
                            <h2 className="product-title">
                              {item.name + "×" + item.qty}
                            </h2>
                          </td>

                          <td className="price-col">
                            <span>
                              {process.env.React_APP_CURRENCY_SYMBOLS}
                              {item.price.toFixed(2)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr className="cart-subtotal">
                        <td>
                          <h4>Subtotal</h4>
                        </td>

                        <td className="price-col">
                          <span>
                            {process.env.React_APP_CURRENCY_SYMBOLS}
                            {getCartTotal(cartDetail).toFixed(2)}
                          </span>
                        </td>
                      </tr>
                      <tr className="order-total">
                        <td>
                          <h4>Total</h4>
                        </td>
                        <td>
                          <b className="total-price">
                            <span>
                              {process.env.React_APP_CURRENCY_SYMBOLS}
                              {getCartTotal(cartDetail).toFixed(2)}
                            </span>
                          </b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    cartList: state.cartlist.cart ? state.cartlist.cart : [],
  };
};

export default ThankForOrder;
