export const actionTypes = {
  SetUserProfile: "SET_USER_PROFILE_DATA",
  isError: "API_ERROR",
  isRegError: "REG_API_ERROR",

  isLoading: "SET_LOADING",
  UpdateCart: "UPDATE_CART",
  PaymentMethods: "PAYMENT_METHODS",
};

const initialState = {
  isLoading: false,
  isError: false,
  isLogin: false,
  isRegError: false,
  regMessage: "",
  errorMessage: "",
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.SetUserProfile:
      return {
        ...state,
        isLoading: false,
        isLogin: true,
        userProfile: action.payload,
      };
    case actionTypes.isError:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };

    case "SET_SINGLE_LOADING":
      return {
        ...state,
        isSingleLoading: true,
      };
    case "SET_SINGLE_PRODUCTS":
      return {
        ...state,
        isSingleLoading: false,
        singleProduct: action.payload,
      };

    case "SINGLE_API_ERROR":
      return {
        ...state,
        isSingleLoading: false,
        isError: true,
      };
    case "SET_SEARCH_FILTERS":
      return {
        ...state,
        searchFilter: action.payload,
      };
    case actionTypes.isRegError:
      return {
        ...state,
        isRegError: true,
        regMessage: action.payload,
      };

    default:
      return state;
  }
};

export default UserReducer;
