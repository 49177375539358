import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

// Import Custom Component
import CartPopup from "../components/features/modals/add-to-cart-popup";

export const actionTypes = {
  AddToCart: "ADD_TO_CART",
  RemoveFromCart: "REMOVE_FROM_CART",
  RefreshStore: "REFRESH_STORE",
  UpdateCart: "UPDATE_CART",
  PaymentMethods: "PAYMENT_METHODS",
  orderPlaced: "ORDER_PLACED",
  RemoveCartItems: "REMOVE_CART_ITEMS",
};

const initialState = {
  cart: [],
  paymentMethods: [],
  placedOrderStatus: false,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AddToCart:
      const productSlug = action.payload.product.slug;

      if (
        state.cart.findIndex(
          (product) =>
            product.slug === productSlug && product.index === action.index
        ) !== -1
      ) {
        const cart = state.cart.reduce((cartAcc, product) => {
          if (product.slug === productSlug && product.index === action.index) {
            cartAcc.push({
              ...product,
              qty: parseInt(product.qty) + parseInt(action.payload.qty),
              sum:
                (product.sale_price !== null
                  ? product.sale_price
                  : product.price) *
                (parseInt(product.qty) + parseInt(action.payload.qty)),
            }); // Increment qty
          } else {
            cartAcc.push(product);
          }

          return cartAcc;
        }, []);
        return { ...state, cart };
      }

      const price =
        action.payload.product.variants.length &&
        !action.payload.product.variants.find(
          (item, index) =>
            index === action.payload.variant && item.price === null
        )
          ? action.payload.product.variants[action.payload.variant].sale_price
            ? action.payload.product.variants[action.payload.variant].price
            : action.payload.product.variants[action.payload.variant].price
          : action.payload.product.sale_price
          ? action.payload.product.sale_price
          : action.payload.product.price;

      return {
        ...state,
        cart: [
          ...state.cart,
          {
            ...action.payload.product,
            qty: action.payload.qty,
            price: parseFloat(price),
            index: action.index,
          },
        ],
      };

    case actionTypes.UpdateCart:
      return {
        ...state,
        cart: action.payload.product,
      };

    case actionTypes.RemoveFromCart:
      let cart = state.cart.reduce((cartAcc, product) => {
        if (
          product.slug !== action.payload.product.slug ||
          product.index !== action.payload.product.index
        ) {
          cartAcc.push(product);
        }

        return cartAcc;
      }, []);

      return { ...state, cart };

    case actionTypes.RefreshStore:
      return initialState;

    case actionTypes.PaymentMethods:
      return {
        ...state,
        paymentMethods: action.payload,
      };
    case actionTypes.orderPlaced:
      return {
        ...state,
        placedOrderStatus: true,
      };
    case actionTypes.RemoveCartItems:
      let items = [];
      return { ...state, cart: items };
    default:
      return state;
  }
};

export const actions = {
  addToCart: (product, qty = 1, index = -1) => ({
    type: actionTypes.AddToCart,
    payload: { product },
    qty,
    index,
  }),
  removeFromCart: (product) => ({
    type: actionTypes.RemoveFromCart,
    payload: { product },
  }),
  updateCart: (products) => ({
    type: actionTypes.UpdateCart,
    payload: { products },
  }),
};

export function* cartSaga() {
  yield takeEvery(actionTypes.AddToCart, function* saga(e) {
    toast(<CartPopup product={{ ...e.payload.product, index: e.index }} />);
  });
}

const persistConfig = {
  keyPrefix: "telecart-",
  key: "cart",
  storage,
};

export default cartReducer;
//export default persistReducer(persistConfig, cartReducer);
