import { connect } from "react-redux";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ALink from "../components/common/ALink";
import { useWishListContext } from "../context/wishlistContext";
import { useProductContext } from "../context/productContext";
import { useEffect } from "react";
import { useCartContext } from "../context/cartContext";

const Wishlist = () => {
  const { wishListItem, removeFromWishlist } = useWishListContext();
  const { addToCart } = useCartContext();
  const [flag, setFlag] = useState(0);
  
  useEffect(() => {
    document.querySelector("body").addEventListener("click", onBodyClick);
    return () => {
      document.querySelector("body").removeEventListener("click", onBodyClick);
    };
  }, []);

  function onBodyClick(e) {
    if (e.target.closest(".header-search"))
      return (
        e.target.closest(".header-search").classList.contains("show-results") ||
        e.target.closest(".header-search").classList.add("show-results")
      );

    document.querySelector(".header-search.show") &&
      document.querySelector(".header-search.show").classList.remove("show");
    document.querySelector(".header-search.show-results") &&
      document
        .querySelector(".header-search.show-results")
        .classList.remove("show-results");
  }

  const onMoveFromToWishlit = (e, item) => {
    setFlag(2);
    e.preventDefault();
    addToCart(item, 1, 1);
    removeFromWishlist(item);
  };

  const removeProduct = (e, item) => {
    setFlag(1);
    e.preventDefault();
    removeFromWishlist(item);
  };

  return (
    <main className="main">
      <div className="page-header">
        <div className="container d-flex flex-column align-items-center">
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <ALink href="/">Home</ALink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Wishlist
                </li>
              </ol>
            </div>
          </nav>

          <h1>Wishlist</h1>
        </div>
      </div>

      <div className="container">
        <div className="success-alert">
          {flag === 1 ? <p>Product successfully removed.</p> : ""}
          {flag === 2 ? <p>Product added to cart successfully.</p> : ""}
        </div>
        <div className="wishlist-title">
          <h2>My wishlist</h2>
        </div>
        {wishListItem.length === 0 ? (
          <div className="wishlist-table-container">
            <div className="table table-wishlist mb-0">
              <div className="wishlist-empty-page text-center">
                <i className="far fa-heart"></i>
                <p>No products added to the wishlist</p>
                <ALink
                  href="/products"
                  className="btn btn-dark btn-add-cart product-type-simple btn-shop font1 w-auto"
                >
                  go shop
                </ALink>
              </div>
            </div>
          </div>
        ) : (
          <div className="wishlist-table-container">
            <table className="table table-wishlist mb-0">
              <thead>
                <tr>
                  <th className="thumbnail-col"></th>
                  <th className="product-col">Product</th>
                  <th className="price-col">Price</th>
                  <th className="status-col">Stock Status</th>
                  <th className="action-col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {wishListItem.map((item, index) => (
                  <tr key={"wishlist-item" + index} className="product-row">
                    <td className="media-with-lazy">
                      <figure className="product-image-container">
                        <ALink
                          href={`/product/${item.slug}`}
                          className="product-image"
                        >
                          <LazyLoadImage
                            alt="product"
                            src={item.images[0]["media"].url}
                            threshold={500}
                            width="80"
                            height="80"
                          />
                        </ALink>
                        <a
                          href="#"
                          className="btn-remove icon-cancel"
                          title="Remove Product"
                          onClick={(e) => removeProduct(e, item)}
                        ></a>
                      </figure>
                    </td>
                    <td>
                      <h5 className="product-title">
                        <ALink href={`/product/${item.slug}`}>
                          {item.name}
                        </ALink>
                      </h5>
                    </td>
                    <td>
                      <div className="price-box">
                        {item.price == item.sale_price ? (
                          <span className="product-price">
                            {process.env.React_APP_CURRENCY_SYMBOLS +
                              item.price}
                          </span>
                        ) : (
                          <>
                            <span className="old-price">
                              {process.env.React_APP_CURRENCY_SYMBOLS +
                                item.price}
                            </span>
                            <span className="new-price">
                              {process.env.React_APP_CURRENCY_SYMBOLS +
                                item.sale_price}
                            </span>
                          </>
                        )}
                      </div>
                    </td>
                    <td>
                      <span className="stock-status">
                        {item.is_out_of_stock ? "Out of stock" : "In stock"}
                      </span>
                    </td>
                    <td className="action">
                      <ALink
                        href={`/product/${item.slug}`}
                        className="btn btn-quickview mt-1 mt-md-0"
                        title="View"
                      >
                        View
                      </ALink>
                      {item.variants.length > 0 ? (
                        <ALink
                          className="btn btn-dark btn-add-cart product-type-simple btn-shop"
                          href={`/product/default/${item.slug}`}
                        >
                          select options
                        </ALink>
                      ) : (
                        <button
                          className="btn btn-dark btn-add-cart product-type-simple btn-shop"
                          onClick={(e) => {
                            onMoveFromToWishlit(e, item);
                          }}
                        >
                          ADD TO CART
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </main>
  );
};
export default Wishlist;
