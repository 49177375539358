import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

// Import Custom Component
import ALink from "../../common/ALink";

function CartPopup(props) {
  const { product } = props;
  const pro = product.product;

  return (
    <div className="minipopup-area">
      <div className="minipopup-box" style={{ top: "0" }}>
        <div className="product media-with-lazy">
          <figure className="product-media w-100">
            <ALink href={`/product/${pro.slug}`}>
              <LazyLoadImage
                alt="product"
                src={pro.images ? `${pro.images[0].media.url}` : ""}
                threshold={500}
                effect="black and white"
                width="100%"
                height="auto"
              />
            </ALink>
          </figure>
          <div className="product-detail">
            {pro.index > -1 ? (
              !pro.variants[pro.index].color ? (
                <ALink className="product-name" href={`/product/${pro.slug}`}>
                  {pro.name + " - " + pro.variants[pro.index].size.name}
                </ALink>
              ) : !pro.variants[pro.index].size ? (
                <ALink className="product-name" href={`/product/${pro.slug}`}>
                  {pro.name + " - " + pro.variants[pro.index].color.name}
                </ALink>
              ) : (
                <ALink className="product-name" href={`/product/${pro.slug}`}>
                  {pro.name +
                    " - " +
                    pro.variants[pro.index].color.name +
                    ", " +
                    pro.variants[pro.index].size.name}
                </ALink>
              )
            ) : (
              <ALink className="product-name" href={`/product/${pro.slug}`}>
                {pro.name}
              </ALink>
            )}

            <p>has been added to your cart.</p>
          </div>
        </div>
        <div className="product-action">
          <ALink href="/cart" className="btn viewcart">
            View Cart
          </ALink>
          <ALink href="/checkout" className="btn btn-dark checkout">
            Checkout
          </ALink>
        </div>
        <button className="mfp-close"></button>
      </div>
    </div>
  );
}

export default React.memo(CartPopup);
