const initialState = {
  isLoading: false,
  isError: false,
  categories: [],
};

const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "SET_ALL_CATEGORIES":
      return {
        ...state,
        isLoading: false,
        categories: action.payload,
      };
    case "API_ERROR":
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      return state;
  }
};

export default CategoryReducer;
