import axios from "axios";
import { useContext, useReducer } from "react";
import { createContext } from "react";

import reducer, { actionTypes } from "../reducer/reducerUser";
import { useEffect } from "react";

const UserContext = createContext();

const getProfileData = () => {
  var profileData = localStorage.getItem("tele-user-profile");

  if (
    typeof profileData === "undefined" ||
    profileData === "undefined" ||
    profileData === null ||
    profileData.length === 0
  ) {
    return null;
  } else {
    return JSON.parse(profileData);
  }
};
const isLoggedin = () => {
  var isLoggined = localStorage.getItem("tele-loggedin");
  var profileData = localStorage.getItem("tele-user-profile");
  if (
    typeof isLoggined === "undefined" ||
    isLoggined === "undefined" ||
    isLoggined === null ||
    isLoggined === "null" ||
    isLoggined === "false" ||
    profileData === null ||
    profileData === "null"
  ) {
    return false;
  } else {
    return true;
  }
};

const initialState = {
  isLoading: false,
  isError: false,
  isRegError: false,
  isLogin: isLoggedin(),
  userProfile: getProfileData(),
  regMessage: "",
  errorMessage: "",
};

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    localStorage.setItem("tele-loggedin", state.isLogin);
    localStorage.setItem(
      "tele-user-profile",
      JSON.stringify(state.userProfile)
    );
  }, [state.userProfile]);

  const loginHandler = async (login_data) => {
    dispatch({ type: actionTypes.isLoading });
    try {
      const result = await axios.post(
        process.env.React_APP_API_ENDPOINT + "login",
        login_data
      );
      const res = await result.data;
      const data = res;

      if (data.status === 200) {
        dispatch({ type: actionTypes.SetUserProfile, payload: data.data });
      } else {
        alert(JSON.stringify(data.message));
        dispatch({ type: actionTypes.isError, payload: data.message });
      }
    } catch (error) {
      dispatch({
        type: actionTypes.isError,
        payload: "username or password not match.",
      });
    }
  };

  const registerHandler = async (reg_data) => {
    dispatch({ type: actionTypes.isLoading });
    try {
      const result = await axios.post(
        process.env.React_APP_API_ENDPOINT + "registration",
        reg_data
      );
      const res = await result.data;
      const data = res;
      console.log(data);
      if (data.status === 200 && data.success === true) {
        
        dispatch({ type: actionTypes.SetUserProfile, payload: data.data });
      } else {
        dispatch({ type: actionTypes.isRegError, payload: data.message });
      }
    } catch (error) {
      dispatch({
        type: actionTypes.isRegError,
        payload: "User already exist.",
      });
    }
  };

  return (
    <UserContext.Provider value={{ ...state, loginHandler, registerHandler }}>
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = () => {
  return useContext(UserContext);
};

export { UserProvider, UserContext, useUserContext };
