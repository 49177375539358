const initialState = {
  isLoading: false,
  isError: false,
  products: [],
  featureProduct: [],
  newProduct:[],
  topRated:[],
  hotProduct: [],
  isSingleLoading: false,
  singleProduct: {},
};

const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "SET_ALL_PRODUCTS":
      const featureProducts = action.payload.filter((e) => {
        return e.featured === 1;
      });
      const hotProducts = action.payload.filter((e) => {
        return e.is_hot === 1;
      });
      const newProducts = action.payload.filter((e) => {
        return e.is_new === 1;
      });
      const topRated = action.payload.filter((e) => {
        return e.is_top === 1;
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        featureProduct: featureProducts,
        hotProduct: hotProducts,
        newProduct:newProducts,
        topRated:topRated
      };
    case "API_ERROR":
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case "SET_SINGLE_LOADING":
      return {
        ...state,
        isSingleLoading: true,
      };
    case "SET_SINGLE_PRODUCTS":
      return {
        ...state,
        isSingleLoading: false,
        singleProduct: action.payload,
      };

    case "SINGLE_API_ERROR":
      return {
        ...state,
        isSingleLoading: false,
        isError: true,
      };
    case "SET_SEARCH_FILTERS":
      return {
        ...state,
        searchFilter: action.payload,
      };

    default:
      return state;
  }
};

export default ProductReducer;
