import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider, useStore } from "react-redux";
import { AppProvider } from "./context/productContext";
import { CartProvider } from "./context/cartContext";
import { PersistGate } from "redux-persist/integration/react";
import { CategoriesProvider } from "./context/categoriesContext";
import { UserProvider } from "./context/UserContext";
import { WishListProvider } from "./context/wishlistContext";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AppProvider>
    <CategoriesProvider>
      <UserProvider>
        <CartProvider>
          <WishListProvider>
            <App />
          </WishListProvider>
        </CartProvider>
      </UserProvider>
    </CategoriesProvider>
  </AppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
