import React, { useEffect } from "react";
import { connect } from "react-redux";

// Import Actions
import { actions as CartAction } from "../../../store/cart";

// Import Custom Component
import ALink from "../ALink";

// Import Utils
import { getCartTotal } from "../../../utils";
import { useRoutes } from "react-router";
import { NavLink } from "react-router-dom";
import { useCartContext } from "../../../context/cartContext";

function CartMenu(props) {
  const { removeToCart } = useCartContext();
  const { cartItems } = props;
  const router = ""; //useRoutes();

  useEffect(() => {
    // router.events.on('routeChangeStart', cartClose);

    return () => {
      // router.events.off('routeChangeStart', cartClose);
    };
  }, []);

  function toggleCart(e) {
    e.preventDefault();
    document.querySelector("body").classList.toggle("cart-opened");
  }

  function cartClose() {
    document.querySelector("body").classList.contains("cart-opened") &&
      document.querySelector("body").classList.remove("cart-opened");
  }

  function getQtyTotal(items) {
    let total = 0;
    if (items) {
      for (let i = 0; i < items.length; i++) {
        total += parseInt(items[i].qty, 10);
      }
    }
    return total;
  }

  function removeFromCart(e, cart) {
    e.preventDefault();
    removeToCart(cart);
  }

  return (
    <div className="dropdown cart-dropdown">
      <NavLink
        href="#"
        title="Cart"
        className="dropdown-toggle dropdown-arrow cart-toggle d-flex align-items-center"
        onClick={toggleCart}
      >
        <i className="minicart-icon"></i>
        <span className="cart-count badge-circle">
          {getQtyTotal(cartItems)}
        </span>
      </NavLink>

      <div className="cart-overlay" onClick={cartClose}></div>

      <div className="dropdown-menu mobile-cart">
        <NavLink
          href="#"
          title="Close (Esc)"
          className="btn-close"
          onClick={(e) => {
            cartClose();
            e.preventDefault();
          }}
        >
          ×
        </NavLink>

        <div className="dropdownmenu-wrapper">
          <div className="dropdown-cart-header">Shopping Cart</div>

          {typeof cartItems !=="undefined" && cartItems.length > 0 ? (
            <>
              <div className="dropdown-cart-products">
                {cartItems.map((cart, index) => (
                  <div className="product" key={"cartItems" + index}>
                    <div className="product-details">
                      <h2 className="product-title">
                        {cart.index > -1 ? (
                          !cart.variants[cart.index].color ? (
                            <ALink href={`/product/${cart.slug}`}>
                              {cart.name +
                                " - " +
                                cart.variants[cart.index].size.name}
                            </ALink>
                          ) : !cart.variants[cart.index].size ? (
                            <ALink href={`/product/${cart.slug}`}>
                              {cart.name +
                                " - " +
                                cart.variants[cart.index].color.name}
                            </ALink>
                          ) : (
                            <ALink href={`/product/${cart.slug}`}>
                              {cart.name +
                                " - " +
                                cart.variants[cart.index].color.name +
                                ", " +
                                cart.variants[cart.index].size.name}
                            </ALink>
                          )
                        ) : (
                          <ALink href={`/product/${cart.slug}`}>
                            {cart.name}
                          </ALink>
                        )}
                      </h2>

                      <span className="cart-product-info">
                        <span className="cart-product-qty">{cart.qty}</span> ×{" "}
                        <span>{process.env.React_APP_CURRENCY_SYMBOLS}</span>{" "}
                        {cart.price.toFixed(2)}
                      </span>
                    </div>

                    <figure className="product-image-container">
                      <ALink
                        href={`/product/${cart.slug}`}
                        className="product-image"
                      >
                        <img
                          src={cart.images[0].media.url}
                          width="78"
                          height="78"
                          alt="product"
                        />
                      </ALink>
                      <NavLink
                        href="#"
                        className="btn-remove icon-cancel"
                        title="Remove Product"
                        onClick={(e) => {
                          removeFromCart(e, cart);
                        }}
                      ></NavLink>
                    </figure>
                  </div>
                ))}
              </div>

              <div className="dropdown-cart-total">
                <span>SUBTOTAL:</span>

                <span className="cart-total-price float-right">
                  {process.env.React_APP_CURRENCY_SYMBOLS +
                    getCartTotal(cartItems).toFixed(2)}
                </span>
              </div>

              <div className="dropdown-cart-action">
                <ALink
                  href="/cart"
                  className="btn btn-gray btn-block view-cart"
                >
                  View Cart
                </ALink>
                <ALink
                  href="/checkout"
                  className="btn btn-dark btn-block text-white"
                >
                  Checkout
                </ALink>
              </div>
            </>
          ) : (
            <p className="pt-3 mt-2">No products in the cart.</p>
          )}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    cartItems: state.cartlist.cart ? state.cartlist.cart : [],
  };
}

export default CartMenu;
