import axios from "axios";
import { useContext, useReducer } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import reducer, { actionTypes } from "../reducer/reducerWishList";

const WishListContext = createContext();
const getWishlistData = () => {
  var wishlist_data = localStorage.getItem("tele-cart-wishlist");

  if (
    typeof wishlist_data === "undefined" ||
    wishlist_data === "undefined" ||
    wishlist_data === null ||
    wishlist_data.length === 0
  ) {
    return [];
  } else {
    return JSON.parse(wishlist_data);
  }
};

const initialState = {
  isLoading: false,
  isError: false,
  wishListItem: getWishlistData(),
};
const WishListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    localStorage.setItem("tele-cart-wishlist", JSON.stringify(state.wishListItem));
  }, [state.wishListItem]);


  const addToWishList = (product) => {
    dispatch({
      type: actionTypes.AddToWishlist,
      payload: { product },
    });
  };
  const removeFromWishlist = (product) => {
    dispatch({
      type: actionTypes.removeFromWishlist,
      payload: { product },
    });
  };
  
  return (
    <WishListContext.Provider value={{ ...state, addToWishList ,removeFromWishlist}}>
      {children}
    </WishListContext.Provider>
  );
};

const useWishListContext = () => {
  return useContext(WishListContext);
};

export { WishListProvider, WishListContext, useWishListContext };
